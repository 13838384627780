import axios from '@/common/axios'
import qs from 'qs'

export function addMaterialProduct (data) {
  return axios({
    method: 'post',
    url: '/storage/materialProduct/add',
    data: qs.stringify(data)
  })
}

export function deleteMaterialProduct (id) {
  return axios({
    method: 'delete',
    url: '/storage/materialProduct/delete/' + id
  })
}

export function updateMaterialProduct (data) {
  return axios({
    method: 'put',
    url: '/storage/materialProduct/update',
    data: qs.stringify(data)
  })
}

export function selectMaterialProductInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/materialProduct/info/' + id
  })
}

export function selectMaterialProductList (query) {
  return axios({
    method: 'get',
    url: '/storage/materialProduct/list',
    params: query
  })
}
