<template>
  <div id="materialProduct">
    <el-dialog
      :title="materialProductFormTitle"
      width="1500px"
      :visible.sync="materialProductDialogVisible"
      :close-on-click-modal="false"
      @close="materialProductDialogClose"
    >
      <el-form
        ref="materialProductFormRef"
        :model="materialProductForm"
        :rules="materialProductFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品编号" prop="productNo">
              <el-select
                v-model="materialProductForm.productNo"
                placeholder="请选择产品"
                clearable
                filterable
                @change="materialProductStockChange"
              >
                <el-option
                  v-for="item in materialProductStockList"
                  :key="item.id"
                  :label="item.productNo"
                  :value="item.productNo"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="materialProductForm.productName"
                placeholder="请输入产品名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="materialProductForm.spec"
                placeholder="请输入型号规格"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="materialProductForm.batchNo"
                placeholder="请输入批号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="materialProductForm.quantity"
                placeholder="请输入数量"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库日期" prop="inDate">
              <el-date-picker
                v-model="materialProductForm.inDate"
                placeholder="请选择入库日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validDate">
              <el-date-picker
                v-model="materialProductForm.validDate"
                placeholder="请选择有效期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在位置" prop="location">
              <el-input
                v-model="materialProductForm.location"
                placeholder="请输入所在位置"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请验单编号" prop="inspectionFormNo">
              <el-input
                v-model="materialProductForm.inspectionFormNo"
                placeholder="请输入请验单编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验报告单编号" prop="inspectionReportNo">
              <el-input
                v-model="materialProductForm.inspectionReportNo"
                placeholder="请输入检验报告单编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="成品放行审核单编号" prop="productAuditNo">
              <el-input
                v-model="materialProductForm.productAuditNo"
                placeholder="请输入成品放行审核单编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结余数量" prop="balanceQuantity">
              <el-input
                v-model="materialProductForm.balanceQuantity"
                placeholder="请输入结余数量"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="出入库情况">
          <vxe-toolbar
            v-if="materialProductFormTitle === '新增产品出入库'
              ||materialProductFormTitle === '修改产品出入库'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column
              field="outInSituation"
              title="出入库情况"
              width="150"
            >
              <template v-slot="{ row, column }">
                <vxe-radio-group
                  v-model="row[column.property]"
                  @change="outInSituationWatch(row[column.property], row)"
                >
                  <vxe-radio :label="1" content="出库" />
                  <vxe-radio :label="2" content="入库" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="applyDept"
              title="申请部门"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="situationExplain"
              title="情况说明"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="outInQuantity"
              title="出入库数量"
              :edit-render="{
                name: '$input',
                immediate: true,
                props: { type: 'number', clearable: true },
                events: { change: updateBalanceQuantity },
              }"
            />
            <vxe-table-column
              field="balanceQuantity"
              title="结余数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column field="outInFormNo" title="出入库申请单编号" width="220">
              <template v-slot="{row, column}">
                <el-input v-model="row[column.property]" placeholder="请输入出入库申请单编号">
                  <i slot="suffix" class="el-input__icon el-icon-success" @click="selectOutInFrom(row)" />
                </el-input>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              v-if="materialProductFormTitle === '新增产品出入库'
                || materialProductFormTitle === '修改产品出入库'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="materialProductDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialProductFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品编号">
        <el-input
          v-model="searchForm.productNo"
          placeholder="请输入产品编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="产品名称">
        <el-input
          v-model="searchForm.productName"
          placeholder="请输入产品名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="批号">
        <el-input
          v-model="searchForm.batchNo"
          placeholder="请输入批号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_MATERIAL_PRODUCT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialProductPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="productNo" label="产品编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="balanceQuantity" label="结余数量" />
      <el-table-column label="入库日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inDate">{{ scope.row.inDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validDate" :style="{ color: scope.row.status === 1 ? 'red' : '#000' }">
            {{ scope.row.validDate.substring(0, 10) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="location" label="所在位置" />
      <el-table-column prop="inspectionFormNo" label="请验单编号" />
      <el-table-column prop="inspectionReportNo" label="检验报告单编号" />
      <el-table-column prop="productAuditNo" label="成品放行审核单编号" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PRODUCT_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PRODUCT_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialProductPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialProduct,
  deleteMaterialProduct,
  updateMaterialProduct,
  selectMaterialProductInfo,
  selectMaterialProductList
} from '@/api/storage/materialProduct'
import { selectMaterialProductStockList } from '@/api/storage/materialProductStock'
import { selectOrderInByNo } from '@/api/universal/orderIn'
import { selectOrderOutByNo } from '@/api/storage/orderOut'
import { selectDeliveryOrderByNo } from '@/api/storage/deliveryOrder'

export default {
  data () {
    return {
      materialProductDialogVisible: false,
      materialProductFormTitle: '',
      materialProductForm: {
        id: '',
        productNo: '',
        productName: '',
        spec: '',
        batchNo: '',
        quantity: '',
        inDate: '',
        validDate: '',
        location: '',
        inspectionFormNo: '',
        inspectionReportNo: '',
        productAuditNo: '',
        balanceQuantity: '',
        type: 1,
        detailJson: ''
      },
      materialProductFormRules: {
        productNo: [{ required: true, message: '产品编号不能为空', trigger: ['blur', 'change']}],
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      materialProductPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productNo: '',
        productName: '',
        batchNo: '',
        type: 1,
        isHistory: 1
      },
      detailList: [],
      materialProductStockList: []
    }
  },
  created () {
    selectMaterialProductList(this.searchForm).then((res) => {
      this.materialProductPage = res
    })
    selectMaterialProductStockList({ type: 1 }).then((res) => {
      this.materialProductStockList = res.list
    })
  },
  methods: {
    materialProductDialogClose () {
      this.$refs.materialProductFormRef.resetFields()
      this.detailList = []
    },
    materialProductFormSubmit () {
      if (this.materialProductFormTitle === '产品出入库详情') {
        this.materialProductDialogVisible = false
        return
      }
      this.$refs.materialProductFormRef.validate(async (valid) => {
        if (valid) {
          this.materialProductForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.materialProductFormTitle === '新增产品出入库') {
            await addMaterialProduct(this.materialProductForm)
          } else if (this.materialProductFormTitle === '修改产品出入库') {
            await updateMaterialProduct(this.materialProductForm)
          }
          this.materialProductPage = await selectMaterialProductList(this.searchForm)
          this.materialProductDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialProductFormTitle = '新增产品出入库'
      this.materialProductDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialProduct(row.id)
        if (this.materialProductPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialProductPage = await selectMaterialProductList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialProductFormTitle = '修改产品出入库'
      this.materialProductDialogVisible = true
      this.selectMaterialProductInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialProductFormTitle = '产品出入库详情'
      this.materialProductDialogVisible = true
      this.selectMaterialProductInfoById(row.id)
    },
    selectMaterialProductInfoById (id) {
      selectMaterialProductInfo(id).then((res) => {
        this.materialProductForm.id = res.id
        this.materialProductForm.productNo = res.productNo
        this.materialProductForm.productName = res.productName
        this.materialProductForm.spec = res.spec
        this.materialProductForm.batchNo = res.batchNo
        this.materialProductForm.quantity = res.quantity
        this.materialProductForm.inDate = res.inDate
        this.materialProductForm.validDate = res.validDate
        this.materialProductForm.location = res.location
        this.materialProductForm.inspectionFormNo = res.inspectionFormNo
        this.materialProductForm.inspectionReportNo = res.inspectionReportNo
        this.materialProductForm.productAuditNo = res.productAuditNo
        this.materialProductForm.balanceQuantity = res.balanceQuantity
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialProductList(this.searchForm).then((res) => {
        this.materialProductPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialProductList(this.searchForm).then((res) => {
        this.materialProductPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialProductList(this.searchForm).then((res) => {
        this.materialProductPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      newRow.outInSituation = 1
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    materialProductStockChange (value) {
      if (value) {
        let materialProductStock = this.materialProductStockList.find(item => item.productNo === value)
        this.materialProductForm.productName = materialProductStock.productName
        this.materialProductForm.spec = materialProductStock.spec
      } else {
        this.materialProductForm.productName = ''
        this.materialProductForm.spec = ''
      }
    },
    updateBalanceQuantity (value, $event) {
      let currentRow = value.row
      let tempIndex = value.$rowIndex
      let total = Number(value.data[tempIndex ? tempIndex - 1 : 0].balanceQuantity)
      let tempNow = Number($event.value)
      if (currentRow.outInSituation == 1) {
        if (tempNow <= total) {
        } else {
          this.$message.error({ message: '库存已不足', center: true })
          tempNow = total
          currentRow.outInQuantity = total
        }
        currentRow.balanceQuantity = total - tempNow
      } else {
        if (tempIndex == 0) {
          currentRow.balanceQuantity = tempNow
        } else {
          currentRow.balanceQuantity = total + tempNow
        }
      }
      this.materialProductForm.balanceQuantity = currentRow.balanceQuantity
    },
    outInSituationWatch ($event, row) {
      let tempBalanceQuantity = row.balanceQuantity
      if ($event == 1) {
        row.balanceQuantity
          = Number(tempBalanceQuantity) - Number(row.outInQuantity)
      } else {
        row.balanceQuantity
          = Number(tempBalanceQuantity) + Number(row.outInQuantity)
      }
      this.materialProductForm.balanceQuantity = row.balanceQuantity
    },
    selectOutInFrom (row) {
      if (row.outInSituation === 1) {
        if (row.outInFormNo.indexOf('C') === 0) {
          selectOrderOutByNo(row.outInFormNo).then(res => {
            for (let item of res.detailList) {
              if (item.materialNo === this.materialProductForm.productNo) {
                row.applyDept = res.receiveDept
                row.operator = res.editor
                row.situationExplain = item.situationExplain
                row.outInQuantity = item.quantity
                return
              }
            }
          })
        } else {
          selectDeliveryOrderByNo(row.outInFormNo).then(res => {
            for (let item of res.orderDetailList) {
              if (item.productNo === this.materialProductForm.productNo) {
                row.applyDept = '销售部'
                row.situationExplain = res.customerName
                row.outInQuantity = item.quantity
                return
              }
            }
          })
        }
      } else {
        selectOrderInByNo(row.outInFormNo).then(res => {
          for (let item of res.detailList) {
            if (item.materialNo === this.materialProductForm.productNo) {
              row.applyDept = res.applyDept
              row.operator = res.editor
              row.situationExplain = item.situationExplain
              row.outInQuantity = item.quantity
              return
            }
          }
        })
      }
    }
  }
}
</script>

<style>
</style>
